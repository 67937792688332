import axios from 'axios';
window.axios = axios;

import jQuery from 'jquery';
window.$ = jQuery;
window.jQuery = jQuery;

import toastr from 'toastr';
window.toastr = toastr;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';


const Evidencesteps = document.getElementsByClassName('evidence--step');
const evidences = document.getElementById('evidences');
var evidenceIndex = 0;

window.EvidenceRight = function() {
    
    evidences.scrollBy(300, 0);
    Evidencesteps[evidenceIndex].classList.remove('evidence--step-active');
    evidenceIndex = (evidenceIndex >= 2 ? evidenceIndex = 2 : evidenceIndex + 1)
    Evidencesteps[evidenceIndex].classList.add('evidence--step-active');
}

window.EvidenceLeft = function() {
    evidences.scrollBy(-300, 0)
    Evidencesteps[evidenceIndex].classList.remove('evidence--step-active');
    evidenceIndex = (evidenceIndex <= 1 ? evidenceIndex = 0 : evidenceIndex - 1)
    Evidencesteps[evidenceIndex].classList.add('evidence--step-active');
}


window.OpenMenu = function() {
    if (document.getElementById('hamburguer').checked) {
        document.getElementById('side-menu').classList.add('menu-enable')
        document.getElementById('side-menu').classList.remove('menu-disable');
        document.getElementById('side-menu').style.display = 'flex';
    } else {
        document.getElementById('side-menu').classList.add('menu-disable')
        setTimeout(function() {
            document.getElementById('side-menu').classList.remove('menu-enable');
            document.getElementById('side-menu').style.display = 'none';
        }, 400);
    }
}


window.OpenForm = function() {
    $('#container-form-modal').css('display','flex');
}
window.CloseForm = function(){
    $('#container-form-modal').css('display','none');
}


window.handlePhone = function(event) {
    let input = event.target
    input.value = phoneMask(input.value)
}

window.phoneMask = function(value){
    if (!value) return ""
    value = value.replace(/\D/g,'')
    value = value.replace(/(\d{2})(\d)/,"($1) $2")
    value = value.replace(/(\d)(\d{4})$/,"$1-$2")
    return value
}

  
const container = document.getElementById('cookies-container');
window.Cookies = function() {
    localStorage.setItem("CookieDisplay", "true");
    container.style.display = 'none'
}
if (!localStorage.getItem("CookieDisplay")) {
    container.style.display = 'block'
}

function ActiveLazy() {
document.querySelectorAll('img').forEach((img, index) => {
    if (img.getBoundingClientRect().top < window.innerHeight) {
        var data_src = img.getAttribute('data-src');
        if(data_src!=null){
            img.src = data_src;
        }
    }
})
}
window.addEventListener('load', ActiveLazy);
window.addEventListener('scroll', ActiveLazy);




function lazy(){
    var windowHeight = window.innerHeight;
    $('.fadein').each(function(i) {
        var bottom_of_object = $(this).offset().top + $(this).outerHeight() - 50;
        var bottom_of_window = $(window).scrollTop() + windowHeight;
        if ($(this).hasClass('fadein-image')) {
            if (bottom_of_window > bottom_of_object - ($(this).height() / 2)) {
                $(this).addClass('revelated');
                $(this).each(function() {
                    if($(this).attr('data-src') && $(this).attr('data-src') != null){
                        $(this).attr('src', $(this).attr('data-src')).removeAttr('data-src');
                    }
                })
                $(this).find('source[data-srcset]').each(function() {
                    $(this).attr('srcset', $(this).attr('data-srcset')).removeAttr('data-srcset')
                })
            }
            //console.log(this);
        } else {
            if (bottom_of_window > bottom_of_object) {
                $(this).addClass('revelated')
            }
        }
    });
}
  
function circle(){
    var scrollTop     = $(window).scrollTop(),
    elementOffset = $('.circle-mov').offset().top,
    distance      = (elementOffset - scrollTop);
    console.log(distance)
}
  
$(document).ready(function() {
    lazy();
});

$(window).scroll(function() {
lazy();
});
  
$('.fadein-image').each(function(){
    var offset = ($(this).offset().top - 63);
    var wind = $(window).height();

    if(offset >= wind){
        lazy();
    }
});
  
//parallax scroll
$(window).on("load scroll", function() {
    var parallaxElement = $(".circle-mov"), parallaxQuantity = parallaxElement.length;
    window.requestAnimationFrame(function() {
    for (var i = 0; i < parallaxQuantity; i++) {
        var currentElement = parallaxElement.eq(i),
        windowTop = $(window).scrollTop(),
        elementTop = currentElement.offset().top,
        elementHeight = currentElement.height(),
        viewPortHeight = window.innerHeight * 0.5 - elementHeight * 0.5,
        scrolled = windowTop - elementTop + viewPortHeight;
        currentElement.css({
        transform: "translate3d(0," + scrolled * -0.15 + "px, 0)"
        });
    }
    });
});

$(document).ready(function(){

    $('button[type="button"]').on('click',function(){
        var idd = $(this).attr('id');
        var has_class_slider = $(this).hasClass('controls-slider');
        var getonclick = $(this).attr('onclick');
        if(idd != 'btn-show-cards' && !has_class_slider){
            OpenForm();
        }
    });

    $('#btn-show-cards').on('click',function(){
        $('#more-cards').addClass('show-cards');
        $('#btn-show-cards').hide();
    });

    const slide = document.getElementById('slider-1');
    const slideItem = document.getElementsByClassName('step');
    const slideNumber = document.getElementsByClassName('step-number');
    var slideIndex = 0

    function slideRight() {
        slide.scrollBy(300, 0);
        slideItem[slideIndex].classList.remove('step--active');
        slideNumber[slideIndex].classList.remove('step-number-active');
        slideIndex = (slideIndex >= 2 ? slideIndex = 2 : slideIndex + 1)
        slideItem[slideIndex].classList.add('step--active');
        slideNumber[slideIndex].classList.add('step-number-active');
    }

    function slideLeft() {
        slide.scrollBy(-300, 0)
        slideItem[slideIndex].classList.remove('step--active');
        slideNumber[slideIndex].classList.remove('step-number-active');
        slideIndex = (slideIndex <= 1 ? slideIndex = 0 : slideIndex - 1)
        slideItem[slideIndex].classList.add('step--active');
        slideNumber[slideIndex].classList.add('step-number-active');
    }


    $('.arrow-right').on('click', function(){
        slideRight();
    });
    $('.arrow-left').on('click', function(){
        slideLeft();
    });
});
